"use client";

import { Box, Divider, Drawer, Grid, IconButton, Typography, useMediaQuery } from "@mui/material";
import { MenuBookOutlined, Menu as MenuIcon } from "@mui/icons-material";
import { FC, memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { usePathname } from 'next/navigation';
import { AllRoles } from "@lib/authorizationRoles";
import { fetchCategories } from "@lib/category/category-client-service";
import { AuthorizationContext } from "@components/AuthorizationContext";
import { saveCategoryToSessionStorage, getPathCategoryReportName } from "@utils/powerBIEntityCategory";
import { checkRole } from "@utils/helpers";
import { Menu } from "./Menu";
import { colors, sideNaveStyles } from "@components/ThemeRegistry/theme";
import { ICategory, IMenuItem } from "@definitions/index";
import { CustomLink } from "@components/CustomLink";
import { ROUTE } from "@constants/common";
import { PAGE_NAMES } from "@constants/pages";
const SideNav: FC<{
  widthCallbackFn?: Function;
}> = ({
  widthCallbackFn
}) => {
  const authenticatedContext = useContext(AuthorizationContext);
  const drawerWidth = sideNaveStyles.width;
  const drawerMiniWidth = sideNaveStyles.smallWidth;
  const isSetChildMenu = useRef(true);
  const isAllRole = checkRole(authenticatedContext, AllRoles);
  const isMobile = useMediaQuery('(max-width:1024px)');
  const [open, setOpen] = useState(false);
  const toggleDrawer = (state: any) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(state);
  };
  const MenuItems: IMenuItem[] = useMemo(() => {
    return [{
      id: "dashboard",
      text: PAGE_NAMES.DASHBOARD,
      icon: "/power-bi.png",
      guardActive: isAllRole,
      children: [{
        id: "dashboard/escheatment",
        text: PAGE_NAMES.ESCHEATMENT,
        pageName: "Dashboard - Escheatment",
        pageDescription: "",
        href: "/dashboard/escheatment",
        guardActive: true
      }, {
        id: "dashboard/revenue-recognition",
        text: PAGE_NAMES.REVENUE_RECOGNITION,
        pageName: "Dashboard - Revenue Recognition",
        pageDescription: "",
        href: "/dashboard/revenue-recognition",
        guardActive: true
      }, {
        id: "dashboard/forecasting",
        text: PAGE_NAMES.FORECASTING,
        pageName: "Dashboard - Forecasting",
        pageDescription: "",
        href: "/dashboard/forecasting",
        guardActive: true
      }]
    }, {
      id: "ad-hoc-query",
      text: PAGE_NAMES.AD_HOC_QUERY,
      pageName: PAGE_NAMES.AD_HOC_QUERY,
      pageDescription: " ",
      href: "/ad-hoc-query",
      icon: "/search-database.png",
      guardActive: isAllRole
    }, {
      id: "report-library",
      text: PAGE_NAMES.REPORT_LIBRARY,
      icon: "/combo-chart.png",
      guardActive: isAllRole,
      children: []
    }, {
      id: "user-management",
      text: PAGE_NAMES.USER_MANAGEMENT,
      pageName: PAGE_NAMES.USER_MANAGEMENT,
      pageDescription: "This page is for managing users, Pending invites are pinned at the top",
      href: "/user-management",
      icon: "/user-shield.png",
      guardActive: authenticatedContext.isAADTenant
    }, {
      id: "admin",
      text: PAGE_NAMES.ADMIN,
      icon: "/system-administrator-male.png",
      guardActive: authenticatedContext.isAADAdminRole,
      children: [{
        id: "admin/powerbi",
        text: PAGE_NAMES.POWER_BI_REPORT_MANAGEMENT,
        pageName: PAGE_NAMES.POWER_BI_REPORT_MANAGEMENT,
        pageDescription: "",
        href: "/admin/powerbi",
        guardActive: authenticatedContext.isAADAdminRole
      }, {
        id: "admin/reports-and-roles",
        text: PAGE_NAMES.REPORTS_AND_ROLES,
        pageName: PAGE_NAMES.REPORTS_AND_ROLES,
        pageDescription: "This page is to assign which reports are visible per Role",
        href: "/admin/reports-and-roles",
        guardActive: authenticatedContext.isAADAdminRole
      }]
    }, {
      id: "glossary",
      text: PAGE_NAMES.GLOSSARY,
      pageName: PAGE_NAMES.GLOSSARY,
      pageDescription: "",
      href: "/glossary",
      icon: <MenuBookOutlined />,
      guardActive: isAllRole
    }, {
      id: "master-data",
      text: PAGE_NAMES.MASTER_DATA,
      icon: "/icons8-data-management-66.png",
      guardActive: authenticatedContext.isAADTenant,
      children: [{
        id: "master-data/entity",
        text: PAGE_NAMES.ENTITIES,
        pageName: PAGE_NAMES.ENTITIES,
        pageDescription: "Manage Entities in the system",
        href: "/master-data/entity",
        guardActive: authenticatedContext.isAADTenant
      }, {
        id: "master-data/organization",
        text: PAGE_NAMES.ORGANIZATIONS,
        pageName: PAGE_NAMES.ORGANIZATIONS,
        pageDescription: "This page is for managing Organizations",
        href: "/master-data/organizations",
        guardActive: authenticatedContext.isAADTenant
      }, {
        id: "master-data/state-profile",
        text: PAGE_NAMES.STATE_PROFILES,
        pageName: PAGE_NAMES.STATE_PROFILES,
        pageDescription: "This page is for managing State Profiles",
        href: "/master-data/state-profile",
        guardActive: authenticatedContext.isAADTenant
      }, {
        id: "master-data/data-streams",
        text: PAGE_NAMES.DATA_STREAMS,
        pageName: PAGE_NAMES.DATA_STREAMS,
        pageDescription: "Review and manage Data Streams lorem ipsum dolor sit amet, consectetuer.",
        href: ROUTE.DATA_STREAMS,
        guardActive: authenticatedContext.isAADTenant
      }, {
        id: "master-data/data-layouts",
        text: PAGE_NAMES.DATA_LAYOUTS,
        pageName: PAGE_NAMES.DATA_LAYOUTS,
        pageDescription: "Review and manage Data Layouts.",
        href: ROUTE.DATA_LAYOUTS,
        guardActive: authenticatedContext.isAADTenant
      }, {
        id: "master-data/transaction-codes",
        text: PAGE_NAMES.TRANSACTION_CODES,
        pageName: PAGE_NAMES.TRANSACTION_CODES,
        pageDescription: "Mapping transaction codes.",
        href: ROUTE.TRANSACTION_CODES,
        guardActive: authenticatedContext.isAADTenant
      }, {
        id: "master-data/card-status-codes",
        text: PAGE_NAMES.CARD_STATUS_CODES,
        pageName: PAGE_NAMES.CARD_STATUS_CODES,
        pageDescription: "Map card status codes to standard card status codes",
        href: ROUTE.CARD_STATUS_CODES,
        guardActive: authenticatedContext.isAADTenant
      }, {
        id: "master-data/data-transformations",
        text: PAGE_NAMES.DATA_TRANSFORMATIONS_SHORT,
        pageName: PAGE_NAMES.DATA_TRANSFORMATIONS,
        pageDescription: "",
        href: ROUTE.DATA_TRANSFORMATIONS,
        guardActive: authenticatedContext.isAADTenant
      }]
    }, {
      id: "escheatment-activities",
      text: PAGE_NAMES.ESCHEATMENT_ACTIVITIES,
      icon: "/icons8-inbox-64.png",
      guardActive: isAllRole,
      children: [{
        id: 'escheatment-activities/filings-notices',
        text: PAGE_NAMES.FILINGS_AND_NOTICES,
        pageName: PAGE_NAMES.FILINGS_AND_NOTICES,
        pageDescription: "Review and manage filings and notices",
        href: ROUTE.FILINGS_NOTICES,
        guardActive: isAllRole
      }, {
        id: 'escheatment-activities/reimbursements',
        text: PAGE_NAMES.REIMBURSEMENTS,
        pageName: PAGE_NAMES.REIMBURSEMENTS,
        pageDescription: "Review accounts with activity after filing",
        href: ROUTE.REIMBURSEMENTS,
        guardActive: isAllRole
      }, {
        id: 'escheatment-activities/simulation',
        text: PAGE_NAMES.SIMULATIONS,
        pageName: PAGE_NAMES.SIMULATIONS,
        pageDescription: "",
        href: ROUTE.SIMULATIONS,
        guardActive: authenticatedContext.isAADAdminRole
      }]
    }, {
      id: "reviews-and-approvals",
      text: PAGE_NAMES.REVIEWS_AND_APPROVALS,
      pageName: PAGE_NAMES.REVIEWS_AND_APPROVALS,
      pageDescription: "Review and approve requests",
      href: ROUTE.REVIEWS_AND_APPROVALS,
      icon: <MenuBookOutlined />,
      guardActive: isAllRole
    }];
  }, [isAllRole, authenticatedContext]);
  const [menuItems, setMenuItems] = useState(MenuItems);
  const [currentPageInfo, setCurrentPageInfo] = useState<IMenuItem>();
  const currentUrl = usePathname();
  const pageInfos: any[] = [];
  menuItems.forEach(item => {
    if (item.children) {
      item.children.forEach(child => {
        if (child.href) {
          pageInfos.push(child);
        }
      });
    }
    if (item.href) {
      pageInfos.push(item);
    }
  });
  useEffect(() => {
    setCurrentPageInfo(pageInfos.find(page => currentUrl.startsWith(`/${page.id}`)));
  }, [currentUrl]);
  const mapCategoriesToMenuItems = useCallback((res: ICategory[]) => {
    return res?.map((category: ICategory) => {
      if (category?.name !== undefined && category.pbiEntities && category.pbiEntities.length > 0) {
        const sortedEntities = category.pbiEntities.toSorted((a, b) => a.name.localeCompare(b.name));
        return {
          id: `report-library/${getPathCategoryReportName(category.name)}`,
          text: category.name,
          pageName: `Report Library - ${category.name}`,
          href: `/report-library/${getPathCategoryReportName(category.name)}/${getPathCategoryReportName(sortedEntities[0]?.name)}`,
          guardActive: true
        };
      }
      return {} as IMenuItem;
    });
  }, []);
  useEffect(() => {
    if (isSetChildMenu.current && authenticatedContext) {
      const fetchData = async () => {
        const updatedMenuItems = [...MenuItems];
        const storageCategoriesString = sessionStorage.getItem("categories");
        if (storageCategoriesString !== null) {
          const storageCategories = JSON.parse(storageCategoriesString) as ICategory[];
          MenuItems[2].children = mapCategoriesToMenuItems(storageCategories);
          setMenuItems(updatedMenuItems);
        } else {
          await fetchCategories(authenticatedContext.refreshToken(), (res: ICategory[]) => {
            saveCategoryToSessionStorage(res);
            MenuItems[2].children = mapCategoriesToMenuItems(res);
            setMenuItems(updatedMenuItems);
          }, undefined, true);
        }
      };
      fetchData();
      isSetChildMenu.current = false;
    }
  }, [authenticatedContext]);
  useEffect(() => {
    if (widthCallbackFn) {
      widthCallbackFn(isMobile ? drawerMiniWidth : drawerWidth);
    }
  }, [isMobile]);
  return <Box data-sentry-element="Box" data-sentry-component="SideNav" data-sentry-source-file="SideNav.tsx">
			{isMobile && <Drawer open={isMobile} variant="permanent" anchor='left' sx={{
      width: drawerMiniWidth,
      minWidth: drawerMiniWidth
    }}>
					<Box sx={{
        backgroundColor: "primaryNavy.main",
        height: "100vh",
        width: drawerMiniWidth,
        paddingLeft: '11px',
        paddingTop: "2px"
      }}>
						<IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)} sx={{
          color: "white"
        }}>
							<MenuIcon />
						</IconButton>
					</Box>
				</Drawer>}

			<Drawer variant={isMobile ? 'temporary' : 'permanent'} anchor='left' open={open} onClose={toggleDrawer(false)} sx={{
      width: drawerWidth,
      "& .MuiDrawer-paper": {
        backgroundColor: "primaryNavy.main",
        color: "common.white",
        width: "inherit",
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        // Firefox
        '&::-webkit-scrollbar': {
          display: 'none' // Webkit browsers like Chrome, Safari
        }
      }
    }} data-sentry-element="Drawer" data-sentry-source-file="SideNav.tsx">
				<Box sx={{
        paddingLeft: sideNaveStyles.paddingLeft,
        minHeight: sideNaveStyles.headerMinHeight,
        alignContent: "center"
      }} data-sentry-element="Box" data-sentry-source-file="SideNav.tsx">
					<CustomLink href="/" data-sentry-element="CustomLink" data-sentry-source-file="SideNav.tsx">
						<Box component='img' src='/cc-logo-green-white.svg' alt='Card Compliant' sx={{
            width: '205px',
            height: "58px",
            cursor: 'pointer'
          }} data-sentry-element="Box" data-sentry-source-file="SideNav.tsx" />
					</CustomLink>
				</Box>

				<Divider sx={{
        borderColor: colors.divider
      }} data-sentry-element="Divider" data-sentry-source-file="SideNav.tsx" />

				<Box sx={{
        paddingLeft: sideNaveStyles.paddingLeft,
        paddingRight: sideNaveStyles.paddingRight,
        paddingTop: "20px",
        paddingBottom: "20px",
        minHeight: "170px"
      }} data-sentry-element="Box" data-sentry-source-file="SideNav.tsx">
					<Typography sx={{
          fontWeight: 500,
          fontSize: "30px",
          lineHeight: "40px"
        }} data-sentry-element="Typography" data-sentry-source-file="SideNav.tsx">
						{currentPageInfo ? currentPageInfo.pageName : "Dashboard"}
					</Typography>
					<Typography sx={{
          fontWeight: 400,
          fontSize: "13px",
          lineHeight: "20px",
          paddingTop: "5px"
        }} data-sentry-element="Typography" data-sentry-source-file="SideNav.tsx">
						{currentPageInfo?.pageDescription ?? ""}
					</Typography>
				</Box>
				<Box sx={{
        paddingLeft: sideNaveStyles.paddingLeft,
        paddingRight: sideNaveStyles.paddingRight,
        paddingTop: "0px",
        paddingBottom: "80px"
      }} data-sentry-element="Box" data-sentry-source-file="SideNav.tsx">
					<Menu items={menuItems} data-sentry-element="Menu" data-sentry-source-file="SideNav.tsx" />
					<Divider sx={{
          borderColor: colors.divider
        }} data-sentry-element="Divider" data-sentry-source-file="SideNav.tsx" />
				</Box>
				<Box data-sentry-element="Box" data-sentry-source-file="SideNav.tsx">
					<Divider sx={{
          borderColor: colors.divider
        }} data-sentry-element="Divider" data-sentry-source-file="SideNav.tsx" />
					<Box sx={{
          paddingLeft: sideNaveStyles.paddingLeft,
          paddingRight: sideNaveStyles.paddingLeft,
          paddingTop: "30px",
          paddingBottom: "30px",
          height: "160px",
          minHeight: "160px",
          maxHeight: "160px"
        }} data-sentry-element="Box" data-sentry-source-file="SideNav.tsx">
						<Grid container spacing={2} columns={16} data-sentry-element="Grid" data-sentry-source-file="SideNav.tsx">
							<Grid item xs={8} data-sentry-element="Grid" data-sentry-source-file="SideNav.tsx">
								<Box component='img' src='/cc-logo-white.svg' alt='Card Compliant' sx={{
                width: '100px'
              }} data-sentry-element="Box" data-sentry-source-file="SideNav.tsx" />

							</Grid>
							<Grid item xs={8} data-sentry-element="Grid" data-sentry-source-file="SideNav.tsx">
								<Typography sx={{
                fontWeight: 450,
                fontSize: "12px",
                lineHeight: "16px"
              }} data-sentry-element="Typography" data-sentry-source-file="SideNav.tsx">
									Corporate Headquarters
								</Typography>
								<Typography sx={{
                fontWeight: 350,
                fontSize: "11px",
                lineHeight: "16px",
                paddingTop: "8px"
              }} data-sentry-element="Typography" data-sentry-source-file="SideNav.tsx">
									11610 Ash Street Suite 200 Leawood, KS 66211
								</Typography>

							</Grid>
						</Grid>
					</Box>
				</Box>
			</Drawer>
		</Box>;
};
export default memo(SideNav);