import moment from "moment";
import { DATE_FORMAT, SERVER_DATE_FORMAT } from "@constants/common";
import { IStateProfile } from "./stateProfileDefinitions";
import { IDataStreamItem } from "./dataStreamDefinitions";
import { IReportingSnapshotRequest } from "./reportingSnapshotsDefinitions";

export interface IApprovalRequest {
    id: number;
    typeName: string;
    statusName: string;
    coNumber: string;
    createDate: Date | null;
    createDateString: string | null;
    requestor: string;
    roleNamesToApprove: string;
    status: ApprovalRequestStatus;
    type: ApprovalRequestType;
    approvalRequestDetails?: IApprovalRequestDetail[];
    relatedId?: number;

    //Other details
    isOwner?: boolean;
    hasRelatedDraft?: boolean;
    stateProfile?: IStateProfile;
    dataStream?: IDataStreamItem;
    reportSnapshot?: any; // TODO: update to report snapshot class
};

export class ApprovalRequest implements IApprovalRequest {
    id: number = 0;
    typeName: string = '';
    statusName: string = '';
    coNumber: string = '';
    createDate: Date | null = null
    createDateString: string | null = null;
    requestor: string = '';
    roleNamesToApprove: string = '';
    status: ApprovalRequestStatus = ApprovalRequestStatus.Pending;
    type: ApprovalRequestType = ApprovalRequestType.StateProfileChange;

    approvalRequestDetails?: IApprovalRequestDetail[];
    relatedId?: number;
    data?: any;

    //Other details
    isOwner?: boolean;
    hasRelatedDraft?: boolean;
    stateProfile?: IStateProfile;
    dataStream?: IDataStreamItem;
    reportSnapshot?: IReportingSnapshotRequest;

    constructor(initialFields: any = undefined) {
        if (initialFields) {
            Object.assign(this, initialFields);
            const dateLocal = moment.utc(this.createDate, SERVER_DATE_FORMAT);
            this.createDate = dateLocal?.toDate() ?? null;
            this.createDateString = dateLocal ? dateLocal.format(DATE_FORMAT) : null;
            this.statusName = getApprovalRequestStatusName(this.status);
            this.typeName = getApprovalRequestTypeName(this.type);
            this.hasRelatedDraft = (this.type == ApprovalRequestType.StateProfileChange && !!this.stateProfile)
                || (this.type == ApprovalRequestType.DataStreamChange && !!this.dataStream)

            if (initialFields.approvalRequestDetails && initialFields.approvalRequestDetails.length > 0) {
                this.approvalRequestDetails = initialFields.approvalRequestDetails.map((x: any) => new ApprovalRequestDetail(x));
            }

            if (this.type == ApprovalRequestType.ReportSnapshotChange && initialFields.data) {
                try {
                    this.reportSnapshot = JSON.parse(initialFields.data);
                }
                catch { }
            }
        }
    }
};

export interface IApprovalRequestDetail {
    id: number;
    orderNumber: number;
    statusName: string;
    approvedDate: Date | null;
    approvedDateString?: string | null;
    assignedToName: string;
    comment: string | null;
    roleNamesToApprove: string;
    status: ApprovalRequestStatus;
    assignedTo: string;
};

export class ApprovalRequestDetail implements IApprovalRequestDetail {
    id: number = 0;
    orderNumber: number = 0;
    statusName: string = '';
    approvedDate: Date | null = null
    approvedDateString: string | null = null;
    assignedTo: string = '';
    assignedToName: string = '';
    comment: string = '';
    roleNamesToApprove: string = '';
    status: ApprovalRequestStatus = ApprovalRequestStatus.Pending;

    constructor(initialFields: any = undefined) {
        if (initialFields) {
            Object.assign(this, initialFields);
            if (this.approvedDate) {
                const dateLocal = moment.utc(this.approvedDate, SERVER_DATE_FORMAT);
                this.approvedDate = dateLocal?.toDate() ?? null;
                this.approvedDateString = dateLocal ? dateLocal.format(DATE_FORMAT) : null;
            }

            this.statusName = getApprovalRequestStatusName(this.status);
        }
    }
};

export enum ApprovalRequestFilterType {
    All = 0,
    AssignedToCurrentUser = 1,
    SubmittedByCurrentUser = 2,
}

export enum ApprovalRequestStatus {
    Pending = 0,
    Approved = 1,
    Rejected = 2,
    Cancelled = 3,
}

export enum ApprovalRequestType {
    StateProfileChange = 1,
    DataStreamChange = 2,
    ReportSnapshotChange = 3,
}

export const getApprovalRequestStatusName = (status: ApprovalRequestStatus) => {
    switch (status) {
        case ApprovalRequestStatus.Pending:
            return "Pending Review";
        case ApprovalRequestStatus.Approved:
            return "Approved";
        case ApprovalRequestStatus.Rejected:
            return "Rejected";
        case ApprovalRequestStatus.Cancelled:
            return "Cancelled";
        default:
            return "";
    }
}

export const getApprovalRequestTypeName = (type: ApprovalRequestType) => {
    switch (type) {
        case ApprovalRequestType.StateProfileChange:
            return "State Profile";
        case ApprovalRequestType.DataStreamChange:
            return "Data Stream";
        case ApprovalRequestType.ReportSnapshotChange:
            return "Report Snapshots";
    }
    return "Unknown"
}