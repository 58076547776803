import { AuthenticatedContext } from "@components/AuthorizationContext";
import { ICategory, ISimpleDatasetIDs, ISimpleEntityIDs } from "@definitions/index";
import { fetchPowerBIDatasetIdsAsync, fetchPowerBIEntityIdsAsync } from "@lib/power-bi/powerbi-client-service";

export const getPathCategoryReportName = (name: string) => {
	return name
		?.toLowerCase()
		?.replace(/[^a-zA-Z0-9\s]/g, "")
		?.replace(/\s+/g, "-");
};

export const saveCategoryToSessionStorage = (categories: ICategory[]) => {
	categories.forEach((category) => {
		const nameCountMap: { [name: string]: number } = {};
		category.pbiEntities.forEach((entity) => {
			if (nameCountMap[entity.name]) {
				nameCountMap[entity.name]++;
			} else {
				nameCountMap[entity.name] = 1;
			}
			if (nameCountMap[entity.name] > 1) {
				entity.name = `${entity.name} (${nameCountMap[entity.name]})`;
			}
		});
	});
	sessionStorage.setItem("categories", JSON.stringify(categories));
};

export const saveDataToSessionStorage = (name: string, items: any[]) => {
	sessionStorage.setItem(name, JSON.stringify(items));
};


export const cacheEntitiesAndDatasetsAsync = async (authenticatedContext: AuthenticatedContext) => {
	let datasetIds: ISimpleDatasetIDs[] | null = null;
	let entityIDs: ISimpleEntityIDs[] | null = null;
	const storageDatasetIDsString = sessionStorage.getItem("datasetIDs");
	const storageEntityIDsString = sessionStorage.getItem("entityIDs");
	if (!storageDatasetIDsString) {
		await fetchPowerBIDatasetIdsAsync(authenticatedContext.refreshToken(), (res: ISimpleDatasetIDs[]) => {
			saveDataToSessionStorage("datasetIDs", res);
			datasetIds = res;
		});
	}
	else {
		datasetIds = JSON.parse(
			storageDatasetIDsString
		) as ISimpleDatasetIDs[];
	}
	if (!storageEntityIDsString) {
		await fetchPowerBIEntityIdsAsync(authenticatedContext.refreshToken(), (res: ISimpleEntityIDs[]) => {
			saveDataToSessionStorage("entityIDs", res);
			entityIDs = res;
		}, true);
	}
	else {
		entityIDs = JSON.parse(
			storageEntityIDsString
		) as ISimpleEntityIDs[];
	}
	return { datasetIds, entityIDs };
};