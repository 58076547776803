import { AuthenticationResult } from "@azure/msal-browser";
import { buildRequest, callApiServiceAsync } from "@utils/callApiService";
import { APPROVAL_REQUESTS } from "apiServiceConst";
import { ActionStatus, FormState, IPageCriteria } from "@definitions/index";
import { ApprovalRequest, ApprovalRequestFilterType } from "@definitions/approvalRequestDefinitions";
import { fromErrorToFormState, toFormState } from "@lib/transformationFormState";

export const fetchApprovalRequests = async (
	authResult: AuthenticationResult,
	callBack: Function,
	orgCriteria: IPageCriteria,
	filterType: ApprovalRequestFilterType
) => {
	try {
		const reqHeaders = await buildRequest(authResult);
		let url = `${APPROVAL_REQUESTS}?filterType=${filterType}`;
		const response = await callApiServiceAsync(url, reqHeaders);
		if (!response) {
			return;
		}

		callBack(response.map((x: any) => new ApprovalRequest(x)));
	} catch (error) {
		console.error("Error:", error);
		throw new Error("Failed to fetch approval requests data.");
	}
};

export const fetchApprovalRequestDetails = async (
	authResult: AuthenticationResult,
	id: number
) => {
	try {
		const reqHeaders = await buildRequest(authResult);
		let url = `${APPROVAL_REQUESTS}/${id}`;
		const response = await callApiServiceAsync(url, reqHeaders);
		if (!response) {
			return undefined;
		}

		return new ApprovalRequest(response);

	} catch (error) {
		console.error("Error:", error);
		throw new Error("Failed to fetch approval request details data.");
	}
};

export async function approvalAsync(
	authResult: AuthenticationResult,
	prevState: FormState,
	id: number
) {
	try {
		const reqHeaders = await buildRequest(authResult, "PUT");
		const result = await callApiServiceAsync(`${APPROVAL_REQUESTS}/${id}`, reqHeaders);

		if (!result?.success) {
			return toFormState(ActionStatus.FAIL, result?.messages[0] ?? "Failed to approve request details data.");
		}
		return toFormState(ActionStatus.SUCCESS, result?.messages[0]);
	} catch (error) {
		return fromErrorToFormState(error);
	}
}