"use client";

const baseApiUrl = (path: string) => {
	let baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
	if (baseUrl?.endsWith("/")) {
		baseUrl = baseUrl.substring(0, baseUrl.length - 1);
	}
	return `${baseUrl}${path}`;
};

const GLOSSARY_API_SERVICE_URL = baseApiUrl(`/api/glossaries`);
const CLIENT_API_SERVICE_URL = baseApiUrl(`/api/clients`);
const ROLE_API_SERVICE_URL = baseApiUrl(`/api/roles`);
const ORGANIZATION_ENTITY_API_SERVICE_URL = baseApiUrl(`/api/organizationentities`);
const USER_API_SERVICE_URL = baseApiUrl(`/api/users`);
const CATEGORY_API_SERVICE_URL = baseApiUrl(`/api/categories`);
const POWERBI_REPORT_API_SERVICE_URL = baseApiUrl(`/api/powerbireports`);
const STATE_PROFILE_API_SERVICE_URL = baseApiUrl(`/api/stateprofiles`);
const FILINGS_AND_NOTICES_API_SERVICE_URL = baseApiUrl(`/api/filings-notices`);
const REIMBURSEMENTS_API_SERVICE_URL = baseApiUrl(`/api/reimbursements`);
const NOTIFICATIONS_API_SERVICE_URL = baseApiUrl(`/api/notifications`);
const TRANSACTION_CODES_API_SERVICE_URL = baseApiUrl(`/api/transaction-codes`);
const CARD_STATUS_CODES_API_SERVICE_URL = baseApiUrl(`/api/card-status-codes`);
const APPROVAL_REQUESTS = baseApiUrl(`/api/approval-requests`);
const REPORTS_AND_ROLES_API_SERVICE_URL = baseApiUrl(`/api/reportsandroles`);
const COUNTRIES_API_SERVICE_URL = baseApiUrl(`/api/countries`);
const STATES_API_SERVICE_URL = baseApiUrl(`/api/states`);
const DATA_STREAM_API_SERVICE_URL = baseApiUrl(`/api/data-streams`);
const STANDARD_INDUSTRIES_API_SERVICE_URL = baseApiUrl(`/api/standardindustries`);
const ISSUER_API_SERVICE_URL = baseApiUrl(`/api/issuers`);
const PROCESSOR_API_SERVICE_URL = baseApiUrl(`/api/processors`);
const STATE_PROFILE_STAGING_API_SERVICE_URL = baseApiUrl(`/api/state-profile-drafts`);
const REPORTING_SNAPSHOTS_API_SERVICE_URL = baseApiUrl(`/api/reporting-snapshots`);
const PROGRAM_API_SERVICE_URL = baseApiUrl(`/api/programs`);
const CURRENCY_API_SERVICE_URL = baseApiUrl(`/api/currencies`);
const DATA_LAYOUT_API_SERVICE_URL = baseApiUrl(`/api/data-layouts`);
const DATA_STREAM_DRAFT_API_SERVICE_URL = baseApiUrl(`/api/data-stream-drafts`);
const SILVER_FIELD_API_SERVICE_URL = baseApiUrl(`/api/silver-fields`);

export {
	GLOSSARY_API_SERVICE_URL,
	CLIENT_API_SERVICE_URL,
	ROLE_API_SERVICE_URL,
	ORGANIZATION_ENTITY_API_SERVICE_URL,
	USER_API_SERVICE_URL,
	CATEGORY_API_SERVICE_URL,
	POWERBI_REPORT_API_SERVICE_URL,
	STATE_PROFILE_API_SERVICE_URL,
	FILINGS_AND_NOTICES_API_SERVICE_URL,
	REPORTS_AND_ROLES_API_SERVICE_URL,
	COUNTRIES_API_SERVICE_URL,
	STATES_API_SERVICE_URL,
	DATA_STREAM_API_SERVICE_URL,
	STANDARD_INDUSTRIES_API_SERVICE_URL,
	ISSUER_API_SERVICE_URL,
	PROCESSOR_API_SERVICE_URL,
	STATE_PROFILE_STAGING_API_SERVICE_URL,
	REPORTING_SNAPSHOTS_API_SERVICE_URL,
	PROGRAM_API_SERVICE_URL,
	CURRENCY_API_SERVICE_URL,
	REIMBURSEMENTS_API_SERVICE_URL,
	NOTIFICATIONS_API_SERVICE_URL,
	APPROVAL_REQUESTS,
	TRANSACTION_CODES_API_SERVICE_URL,
	CARD_STATUS_CODES_API_SERVICE_URL,
	DATA_LAYOUT_API_SERVICE_URL,
	DATA_STREAM_DRAFT_API_SERVICE_URL,
	SILVER_FIELD_API_SERVICE_URL,
};
