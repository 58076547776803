"use client";

import { AuthenticatedTemplate, useAccount, useMsal } from "@azure/msal-react";
import { Box, Divider, Popover, Typography } from "@mui/material";
import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { AuthorizationContext } from "@components/AuthorizationContext";
import { PowerBIReportFilterStorage } from "definitions";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { StyledBadge } from "@components/Common";
import AppNotifications from "./AppNotifications";
import { fetchUnreadCountAsync } from "@lib/notifications/notifications-service";
const CurrentUserInfo = () => {
  const {
    instance,
    accounts
  } = useMsal();
  const account = useAccount(accounts[0] || {});
  const nameRef = useRef("");
  const authenticatedContext = useContext(AuthorizationContext);
  const [notificationCount, setNotificationCount] = useState<number | null>();
  if (account?.name) {
    nameRef.current = account.name.replace(',', '');
  }
  const handleLogout = (e: any) => {
    PowerBIReportFilterStorage.clearFilterStorages();
    if (!authenticatedContext || !authenticatedContext.setCallbackRedirect) {
      //Logout in unauthenticated context
      instance.logoutRedirect({
        account: instance.getActiveAccount(),
        onRedirectNavigate: () => false
      });
      return;
    }
    authenticatedContext.setCallbackRedirect(() => instance.logoutRedirect({
      account: instance.getActiveAccount(),
      onRedirectNavigate: () => false
    }));
    if (!authenticatedContext.unsavedChange) {
      instance.logoutRedirect({
        account: instance.getActiveAccount(),
        onRedirectNavigate: () => false
      });
    }
    if (authenticatedContext.callbackUnSavedChange) {
      authenticatedContext.callbackUnSavedChange();
    }
    e.preventDefault();
  };
  const showNotificationList = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const refreshUnreadNotificationCount = () => {
    fetchUnreadCountAsync(authenticatedContext.refreshToken(), (count: number) => {
      setNotificationCount(count);
    });
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  useEffect(() => {
    refreshUnreadNotificationCount();
  }, []);
  return <AuthenticatedTemplate data-sentry-element="AuthenticatedTemplate" data-sentry-component="CurrentUserInfo" data-sentry-source-file="CurrentUserInfo.tsx">
			<Box sx={{
      display: "flex"
    }} data-sentry-element="Box" data-sentry-source-file="CurrentUserInfo.tsx">
				<Box sx={{
        textAlign: "right"
      }} data-sentry-element="Box" data-sentry-source-file="CurrentUserInfo.tsx">
					<Box data-sentry-element="Box" data-sentry-source-file="CurrentUserInfo.tsx">
						<Typography sx={{
            fontWeight: 500,
            fontSize: "24px",
            lineHeight: "30px"
          }} data-sentry-element="Typography" data-sentry-source-file="CurrentUserInfo.tsx">
							Hello, {nameRef.current}
						</Typography>
						<Divider sx={{
            borderColor: "#00000026"
          }} data-sentry-element="Divider" data-sentry-source-file="CurrentUserInfo.tsx" />
					</Box>
					<Box sx={{
          display: "inline-flex"
        }} data-sentry-element="Box" data-sentry-source-file="CurrentUserInfo.tsx">
						<Typography sx={{
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            marginTop: "2px"
          }} data-sentry-element="Typography" data-sentry-source-file="CurrentUserInfo.tsx">
							Manage Profile <span>&nbsp;</span> | <span>&nbsp;</span>
						</Typography>
						<Typography sx={{
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            cursor: 'pointer',
            marginTop: "2px"
          }} onClick={handleLogout} data-sentry-element="Typography" data-sentry-source-file="CurrentUserInfo.tsx">
							Sign Out
						</Typography>
					</Box>
				</Box>
				<Box sx={{
        textAlign: "center",
        ml: "10px"
      }} data-sentry-element="Box" data-sentry-source-file="CurrentUserInfo.tsx">
					<StyledBadge aria-describedby={id} badgeContent={notificationCount} color="error" overlap="circular" sx={{
          mt: "-5px",
          cursor: "pointer"
        }} onClick={showNotificationList} data-sentry-element="StyledBadge" data-sentry-source-file="CurrentUserInfo.tsx">
						<AccountCircleIcon sx={{
            fontSize: "60px"
          }} color="action" data-sentry-element="AccountCircleIcon" data-sentry-source-file="CurrentUserInfo.tsx" />
					</StyledBadge>
					<Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }} transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }} sx={{
          '& .MuiPaper-root': {
            borderRadius: '12px'
          }
        }} data-sentry-element="Popover" data-sentry-source-file="CurrentUserInfo.tsx">
						<AppNotifications handleClose={handleClose} handleRefreshCount={refreshUnreadNotificationCount} data-sentry-element="AppNotifications" data-sentry-source-file="CurrentUserInfo.tsx" />
					</Popover>
				</Box>
			</Box>
		</AuthenticatedTemplate>;
};
export default memo(CurrentUserInfo);